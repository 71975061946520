.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: var(--size-border-width-thin) solid var(--color-brand-400);
  border-radius: var(--size-border-radius-m);
  background-color: var(--color-brand-100);
  cursor: pointer;
  padding: var(--size-spacing-stack-200);
  gap: var(--size-spacing-stack-fluid-100);
}

.link {
  border-width: 0;
  background-color: transparent;
}

.title {
  line-height: var(--font-line-height-normal);
  color: var(--color-neutral-dark-500);
  font-size: var(--size-font-base);
  font-weight: var(--font-weight-semibold);
}

.text {
  line-height: var(--font-line-height-normal);
  color: var(--color-neutral-dark-300);
  font-size: var(--size-font-s);
}
